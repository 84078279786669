let url

if (process.env.NODE_ENV === 'production') {
  url = "https://api.superjaya.id"
}
else {
  url = "http://127.0.0.1:8000"
}

export default url
