import React from 'react'
import { Link } from 'react-router-dom'
import { BiSearch } from 'react-icons/bi'
import { useState } from 'react'
import '../CSS/SearchBar.css'
import serverUrl from '../Utilities/ServerUrl'
import axios from 'axios'
import { useEffect } from 'react'

const SearchBar = ({ placeholder, inputRef }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [searchKey, setSearchKey] = useState("");

  const handleFilter = (e) => {
    setSearchKey(e.target.value)
  }

  useEffect(() => {
    if (searchKey === "") {
      setFilteredData([])
      return
    }

    // Request products, after user stops typing 
    // (considered stops typing if input does not change after 1250ms)
    const delayDebounceFn = setTimeout(() => {
      const encodedSearchKey = encodeURIComponent(searchKey)
      axios.get(`${serverUrl}/data/products/?format=json&search=${encodedSearchKey}`)
      .then(res => {
        setFilteredData(res.data.results)
      })
    }, 1250)

    return () => clearTimeout(delayDebounceFn)
  }, [searchKey]);


  function clearInput() {
    inputRef.current.value = ""
    setSearchKey("")
  }

  return (
    <>
      <div className="searchInput">
        <input
          id="searchKeyInputId"
          type="text"
          placeholder={placeholder}
          onChange={handleFilter}
          ref={inputRef}
        />
        <BiSearch className="input-append-icon" />

        {filteredData.length !== 0 && (
          <div className="searchResult">
            {filteredData && filteredData.slice(0, 5).map((value) => {
              return <Link
                to={`/p/${value.id}/${value.slug}`}
                className="searchItem"
                key={value.id}
                onClick={clearInput}
              >
                <p className='fontRegular'>{value.name}</p>
              </Link>
            })}
          </div>
        )}
      </div>
    </>
  )
}

export default SearchBar