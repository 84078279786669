import React from 'react'
import { Navbar, Nav, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SearchBar from './SearchBar'
import { BiSearch } from 'react-icons/bi'
import '../CSS/Header.css'

const Header = () => {
  const searchInputRef = React.useRef();

  const searchBarFocus = () => {
    setTimeout(() => { searchInputRef.current.focus() }, 1000);
  }

  return (
    <>
      <Navbar className="header fontBold d-print-none" expand="lg" variant="light">
        <div className='d-flex'>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Brand className="mainbrand" as={Link} to={"/"} style={{
            alignItems: "center",
            justifyContent: "center",
          }}>
            <h3>Superjaya</h3>
          </Navbar.Brand>
        </div>

        <div className="d-flex d-lg-none">
          <Navbar.Toggle className='d-flex align-items-center p-0' onClick={searchBarFocus}>
            <BiSearch className='nav-icon' />
          </Navbar.Toggle>

          <Link to="./cart">
            <img
              style={{
                margin: "8px",
                alignItem: "center"
              }
              }
              src="/Images/cart.jpg" alt="Cart Logo" width="32" />
          </Link>
        </div>

        <Navbar.Collapse className="Headerlist justify-content-end fontReg">
          <hr />
          <Nav className='p-2'>
            <Nav.Link as={Link} to={"/c"}>Produk</Nav.Link>
            <Nav.Link as={Link} to={"/help"}>Bantuan</Nav.Link>
            <Nav.Item className='px-0 px-md-2'>
              <SearchBar placeholder="Cari..." className='d-none d-md-block' inputRef={searchInputRef} />
            </Nav.Item>
            <Link to="./cart" className='d-none d-md-block'>
              <img
                style={{
                  height: "30px",
                  margin: "7px",
                  alignItem: "center"
                }
                }
                src="/Images/cart.jpg" alt="Cart Logo" width="32" />
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Button className="wa-button fontBold d-print-none">
        <a href="https://wa.me/message/4IW4UAXXQ3H6D1"><img src="/Images/wa-logo.png" alt="help_button" /></a>
      </Button>

    </>
  )
}

export default Header