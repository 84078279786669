import React from 'react'
import { Pagination } from 'react-bootstrap'

function Paginations({ pageURL, data, currentPage }) {

  currentPage = parseInt(currentPage)

  const pageSize = 25
  const totalPages = Math.ceil(data.count / pageSize);

  const paginationItems = []

  if (currentPage > 1) {
    paginationItems.push(<Pagination.First href={`${pageURL}?page=1`}/>)
    paginationItems.push(<Pagination.Item href={`${pageURL}?page=${currentPage - 1}`}>{currentPage - 1}</Pagination.Item>)
  }

  paginationItems.push(<Pagination.Item active>{currentPage}</Pagination.Item>)

  if (currentPage < totalPages) {
    paginationItems.push(<Pagination.Item href={`${pageURL}?page=${currentPage + 1}`}>{currentPage + 1}</Pagination.Item>)
    paginationItems.push(<Pagination.Last href={`${pageURL}?page=${totalPages}`}/>)
  }

  return (
    <div className='text-center mb-4'>
      <Pagination className="d-flex justify-content-center mb-0 itemPagination">
        {paginationItems}
      </Pagination>
      <span>Page {currentPage} of {totalPages}</span>
    </div>
  );
}

export default Paginations