import { format } from "../Utilities/Currency"
import { paginate } from "./Pagination"
import InvoicePage from "../Components/InvoicePage"
import InvoiceAutographFooter from "../Components/InvoiceAutographFooter"

export function createTotalTableFooter(total) {
  return (
    <tfoot>
      <tr>
        <th className="text-end px-2" colSpan={5}>
          Total
        </th>
        <th className="text-end px-2" colSpan={1}>
          <div className='d-flex justify-content-between'>
            <div>Rp</div>
            <div>{format(total)}</div>
          </div>
        </th>
      </tr>
    </tfoot>
  )
}

export function createTable(thead, tableRows, tfoot = null) {
  return <>
    <table className="items-table">
      {thead}
      <tbody className="invoice-tbody">
        {tableRows}
      </tbody>
      {tfoot}
    </table>
  </>
}

/**
 * Top level invoice pages builder function
 * @param {Any[]} items 
 * @param {JSX.Element} invoiceHead 
 * @param {Delegate} createTableDelegate 
 * @returns list of InvoicePage component.
 */
export function createInvoicePages(items, invoiceHead, createTableDelegate) {

  const paginatedDetails = paginate(items)

  // Create invoice head
  const pages = []

  for (let [pageIndex, page] of paginatedDetails.entries()) {
    const isFirstPage = pageIndex === 0
    const isLastPage = pageIndex === paginatedDetails.length - 1

    // Create invoice table
    const table = createTableDelegate(pageIndex, page, isLastPage)

    let invoiceFooter = null
    // if first page and items length <= 8, 
    // then, render autograph in this same page.
    if (isFirstPage && page.length <= 8) {
      invoiceFooter = <InvoiceAutographFooter />
    }
    // if it is the last page (and not the first page) and the page length <= 12, 
    // then render the autograph in that same page.
    else if (isLastPage && page.length <= 12 && !isFirstPage) {
      invoiceFooter = <InvoiceAutographFooter />
    }

    // Create invoice component
    pages.push((
      <InvoicePage
        key={`invpage#${pageIndex+1}`}
        invoiceHeader={isFirstPage ? invoiceHead : null}
        invoiceTable={table}
        invoiceFooter={invoiceFooter}
      ></InvoicePage>
    ))

    // if first page (and is a last page) and items length > 8 and there is only 1 page, 
    // then, render autograph in a new page.
    // (We want to render the autograph in the last page instead of in the next page).
    if (isFirstPage && page.length > 8 && paginatedDetails.length === 1) {
      pages.push(
        <div key={`invpagef#${pageIndex+1}`} className="invoice-container">
          <InvoiceAutographFooter />
        </div>
      )
    }
    // if it is the last page and this page item is > 12,
    // then render the autograph in a new page.
    else if (isLastPage && page.length > 12) {
      pages.push(
        <div key={`invpagef#${pageIndex+1}`} className="invoice-container">
          <InvoiceAutographFooter />
        </div>
      )
    }
  }

  return { pages }
}