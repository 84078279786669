export default function InvoicePage ({
  invoiceHeader,
  invoiceTable,
  invoiceFooter,
  exportRef,
}) {
  return (
    <div
      className="invoice-container"
      id="invoice-container"
      ref={exportRef}
    >
      {invoiceHeader}
      {invoiceTable}
      {invoiceFooter}
    </div>
  )
}
