const InvoiceHeader = ({orderData}) => {
  const currentDate = new Date(orderData.datetime_created)
  const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`
  return (
    <>
      <div className="row invoice-header">
        <div className="col-6">
          <div className="mainbrand navbar-brand fontBold">
            <h1 className="ms-0">SJ SAMARINDA</h1>
          </div>
        </div>

        <div className="col-6 mt-4" style={{ paddingLeft: "100px" }}>
          <address className="small-text">
            <span className="label text-start">Tanggal</span> :{" "}
            {formattedDate} <br />
            <span className="label text-start">Nomor nota</span> :{" "}
            {orderData.id} <br />
            <span className="label text-start">Dicetak oleh</span> :{" "}
            {orderData.handler_name} <br />
            <span className="label text-start">Pembeli</span> :{" "}
            {orderData.fullname} <br />
          </address>
        </div>
      </div>
      <hr style={{margin: "8px 0px 8px 0px", height: "3px"}}/>
    </>
  );
};

export default InvoiceHeader;
