import React from 'react'
import { Container, Row, Col, Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { RiDeleteBin6Line } from 'react-icons/ri'
import '../CSS/Cart.css'
import {formatter} from '../Utilities/Currency'
import InputQuantity from './InputQuantity'
import useUpdatedCart from '../Hooks/useUpdatedCart'

const Cart = () => {
    const {
        isEmpty,
        items,
        removeItem,
        updateItemQuantity,
    } = useUpdatedCart();


    function countShopTotal(){
        let shopTotal = 0;
        for(let item of items){
            let specificItemPrice 
            if(item.wholesale_discount > 0 && item.quantity >= item.wholesale_minimum_quantity){
                specificItemPrice = item.quantity * (item.price - (item.price * item.wholesale_discount / 100))
                shopTotal = shopTotal + specificItemPrice
            } else {
                specificItemPrice = item.quantity * item.price
                shopTotal = shopTotal + specificItemPrice
            }
        }
        return formatter.format(shopTotal)
    }

    if (isEmpty) 
        return <>
            <div className="text-center noitem FontBold">
                <h1 className="fontBold">Keranjang Kamu masih kosong nih!</h1> 
                <h5 className ="mt-4">Keranjang Belanja Anda kosong. Anda tidak memiliki produk apapun di daftar belanja.!</h5>
                <Link to={"/c"}>
                    <Button >Mulai Belanja</Button>
                </Link>
            </div>
        </> 

  return (
    <>
        <h2 className="text-center fontBold pt-4">Keranjang Belanja Anda</h2>
        <p className='text-center pb-4'>{items.length} unique items.</p>

        <Container fluid>
            <Row 
                className="fontMedium cartheader"
                style={{
                alignItems: "center !important",
                marginBottom: "15px",
            }}>
                <Col lg={6} xs={6} className="text-center">
                    <p>Produk</p>
                </Col>

                <Col lg={3} xs={3} className="text-center">
                    <p>Jumlah</p>
                </Col>

                <Col lg={3} xs={3}className="text-center">
                    <p>Total</p>
                </Col>
            </Row>

            {items.map((item) => (
                <Row key={item.id} className="cartproduct">
                    <Col lg={2} xs={3} className="text-center">
                        <img src={item.images[0]?.image} alt="item_picture"></img>
                    </Col>

                    <Col lg={4} xs={3} className="cartdetail fontRegular">
                        <h6 className="fontBold">{item.name}</h6>
                        {item.quantity >= item.wholesale_minimum_quantity && item.wholesale_discount > 0 ? 
                        <Col> 
                            <p style={{textDecoration: 'line-through'}}>Rp. {item.price}</p>
                            <p>{formatter.format(item.price - (item.price * item.wholesale_discount / 100))}</p>
                        </Col>
                        : <p>{formatter.format(item.price)}</p>}

                        <div onClick={() => removeItem(item.id)}
                        style={{
                            display:'flex',
                            alignItems:'center',
                        }}>
                            <RiDeleteBin6Line/>
                            <p className="removeItem"> 
                                Hapus
                            </p>
                        </div>

                    </Col>

                    <Col lg={3} xs={3} className="text-center">
                        <InputQuantity
                            onDecrement={() => updateItemQuantity(item.id, item.quantity - 1)}
                            onIncrement={() => updateItemQuantity(item.id, item.quantity + 1)}
                            maxVal={item.stock}

                            value={item.quantity} 
                            onChange={(e) => {updateItemQuantity(item.id, parseInt(e.target.value))}}
                            onBlur= {(e) => {
                                const enteredValue = parseInt(e.target.value);
                                if (isNaN(enteredValue) || enteredValue < 1) {
                                    updateItemQuantity(item.id, 1)
                                }  
                                else if (enteredValue > item.stock) {
                                    updateItemQuantity(item.id, item.stock);
                                } 
                                else {
                                    updateItemQuantity(item.id, enteredValue);
                                }
                              }
                            }
                        />
                    </Col>

                    <Col lg={3} xs={3} className="itemPricing text-center">
                    {item.quantity >= item.wholesale_minimum_quantity && item.wholesale_discount > 0 
                        ? <p>{formatter.format(item.quantity * (item.price - (item.price * item.wholesale_discount / 100)))}</p>
                        : <p>{formatter.format(item.quantity * item.price)}</p>
                    }
                    </Col>
                </Row>
            ))}

            <Row className="fontMedium cartTotal">
                <Col lg={9} xs={9} className="text-end subtotal">
                    <p>Total Harga</p>
                </Col>

                <Col lg={3} xs={3} className="text-center subtotal">
                    <p>{countShopTotal()}</p>
                </Col>
            </Row>

            <Row className="fontMedium orderButtondiv">
                <Col lg={9} xs={0} className="">
                </Col>

                <Col lg={3} xs={12} className="text-center">
                    <Link to={`/cart/form`}>
                        <Button className="mt-3 fontRegular orderButton">Pesan</Button>
                    </Link>
                </Col>
            </Row>

        </Container>
    </>
  )
}

export default Cart