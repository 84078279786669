import React from 'react'
import { Link } from 'react-router-dom';
import { formatter } from '../Utilities/Currency';
import '../CSS/ProductList.css'
import '../CSS/ProductCard.css'
import serverUrl from '../Utilities/ServerUrl'

const ProductCart = (props) => {
  const product = props.product;
  let imageUrl = product.images.length > 0 ? `${product.images[0].image}` : null
  if (imageUrl && !imageUrl.startsWith("http")) {
    imageUrl = `${serverUrl}${imageUrl}`
  } else if (!imageUrl) {
    imageUrl = '/Images/no_image.png';
  }

  return (
    <>
      <div className="card flex-fill" style={{'height': '100%'}}>
        <Link to={`p/${product.id}/${product.slug}`}>
          <div className="card-img-tiles" data-abc="true">
            <div className="inner">
              <div className="main-img"><img src={imageUrl} alt={product.name} /></div>
            </div>
          </div>
        </Link>
        <div className="card-body text-center">
          <h5 className="fontMedium card-title text-ellipsis">{product.name}</h5>
          <p className="fontRegular text-muted">{formatter.format(product.price)}</p>
          <Link to={`p/${product.id}/${product.slug}`}>
            <div className="btn btn-outline-dark btn-sm mb-3" data-abc="true">View Products</div>
          </Link>
        </div>
      </div>
    </>
  )
}

export default ProductCart