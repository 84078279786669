import {Routes, Route, Outlet} from 'react-router-dom'
import ProductList from './ProductList'
import Categorized from './Categorized'
import ProductDetail from './ProductDetail'
import Home from './Home'
import Cart from './Cart'
import Form from './Form'
import React from 'react'
import Invoice from './Invoice'
import Help from './Help'
import InvoiceThermal from './InvoiceThermal'
import InvoiceRefund from './Refund/InvoiceRefund.js'

// Allow Web-app to navigate (single app page) //
const Navigation = () => {
  return (
    <>
      <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route path="c" element={<Categorized/>}/>
          <Route path="c/:categorySlug" element={<ProductList/>}/>
          <Route path="p/:productId/:productSlug" element={<ProductDetail/>}/>
          <Route path="cart" element={<Cart/>}/>
          <Route path="cart/form" element={<Form/>}/>
          <Route path="invoice/:orderUUID" element={<Invoice/>}/>
          <Route path="invoice/:orderUUID/t" element={<InvoiceThermal/>}/>
          <Route path="invoice_rtpb/:uuid" element={<InvoiceRefund/>}/>
          <Route path="help" element={<Help/>}/>
      </Routes>

      <Outlet/>
    </>
  )
}

export default Navigation