import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useCart } from 'react-use-cart'
import { useNavigate } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'
import '../CSS/Form.css'
import serverUrl from '../Utilities/ServerUrl'
import Spinner from 'react-bootstrap/Spinner';


const Form = () => {
    const [submitting, setSubmitting] = useState(false)
    const { items, emptyCart } = useCart();
    const navigate = useNavigate();

    function returnHome(response) {
        navigate(`/invoice/${response.data.order_uuid}`, {approved:true});
    }

    const url= `${serverUrl}/data/order/`;
    const [data, setData] = useState({
        fullname: "",
        phone_number: "",
        email: "",
        note: "",
        payment_method: "m-banking",
    })

    const validateForm = () => {
        if (!data.fullname || !data.payment_method) {
            alert("Pastikan Nama Pembeli dan Metode Pembayaran terisi.");
            return false;
        }
        else {
            return true;
        }
    };
    

    function submit(e,){
        e.preventDefault();
        
        const itemIndex = [];
        for (var i=0; i <items.length; i++){
            itemIndex.push({
                product_id: items[i].id,
                pcs: items[i].quantity,
            })
        }

        axios.post(url,{
            fullname: data.fullname,
            phone_number: data.phone_number,
            email: data.email,
            note: data.note,
            items: itemIndex.map(item =>{
                return {
                    product_id: item.product_id,
                    pcs: item.pcs,
                }
            }),
            payment_method: data.payment_method,
        })
        .then(response =>{
            if (response.statusText === 'Created') {
                emptyCart()
                returnHome(response)
            } else {
                alert("Failed to Submit Order, Please try again")
            }
        })
        .catch(err => 
            console.error(err.toJSON())
        )
        .finally(() => {
            setSubmitting(false)
        })
    }

    function handleOnChange(e){
        const newData = {...data}
        newData[e.target.id] = e.target.value
        setData(newData)
    }

    const handleSubmit = (e) => {
        setSubmitting(true)
        if(validateForm()){
            submit(e);
            return
        }
        setSubmitting(false)
    }

  return (
    <>
        <h2 className="formGuide text-center pt-4">Dimohon untuk mengisi formulir kontak dibawah</h2>

        <Container fluid className="formcontainer">
            <Row className="formheader">
                <Col lg={12} className="fontBold">
                    <h2>Formulir Pemesanan</h2>
                </Col>
            </Row>

            <Row style={{
                borderTop:"2px solid black",
                borderBottom:"2px solid black",
            }}>
                <Col  lg={12} className="formcol fontMedium">
                    <label>Nama Pembeli:</label>
                    <input onChange={(e) => handleOnChange(e)} id="fullname" value={data.fullname} type="text" required/> <br/>

                    <label>Nomor Telepon :</label>
                    <input onChange={(e) => handleOnChange(e)} id="phone_number" value={data.phone_number} type="number"/> <br/>

                    <label>Email:</label>
                    <input onChange={(e) => handleOnChange(e)} id="email" value={data.email} type="email"/> <br/>

                    <label>Catatan:</label>
                    <input onChange={(e) => handleOnChange(e)} id="note" value={data.note} type="text"/> <br/>

                    <label>Metode Pembayaran :</label>
                    <select onChange={handleOnChange} id="payment_method" required>
                        <option value="Tunai">Tunai</option>
                        <option value="m-banking" selected>Transfer (M-Banking)</option>
                        <option value="Debit-kredit">Debit / Kredit</option>
                    </select>
                </Col>
            </Row>

            <Row>
                <Col lg={12} className="text-end fontMedium">
                    <Button disabled={submitting} onClick={handleSubmit} className="invoicebutton">
                        {submitting && 
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Submitting</span>
                            </Spinner>
                        }
                        {!submitting && "Kirim"}
                    </Button>
                </Col>
            </Row>
        </Container>
    </>
  )
}

export default Form