import { Row, Col, Button} from 'react-bootstrap'
import { HiMinus, HiPlus } from 'react-icons/hi'

const InputQuantity = (props) => {
  const { onDecrement, onIncrement, maxVal, ...inputProps } = props;
  const quantity = parseInt(inputProps.value)
  return (
    <>
      <Row className="updateQuantityDiv">
        <Col lg={4} md={4} sm={4} xs={4}>
          <Button
            onClick={onDecrement}
            disabled={quantity < 1}
            style={{ backgroundColor: "transparent", color: "black" }}
          >
            <HiMinus
              style={{
                fontSize: "15px",
                cursor: "pointer",
              }}
            />
          </Button>
        </Col>
        <Col lg={4} md={4} sm={4} xs={4}>
          <input
            type="number"
            className="input-quantity"
            value={quantity}
            {...inputProps}
          />
        </Col>
        <Col lg={4} md={4} sm={4} xs={4}>
          <Button
            onClick={onIncrement}
            disabled={quantity >= maxVal}
            style={{ backgroundColor: "transparent", color: "black" }}
          >
            <HiPlus
              style={{
                fontSize: "15px",
                cursor: "pointer",
              }}
            />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default InputQuantity;
