import axios from "axios"
import { useEffect } from "react"
import { useCart } from "react-use-cart"
import serverUrl from '../Utilities/ServerUrl'

const useUpdatedCart = () => {
    const cart = useCart()

    useEffect(() => {
        const fetchItems = async () => {
            const ids = cart.items.map(item => item.id).join(',')
            const response = await axios.get(`${serverUrl}/data/products/?id=${ids}`)
            if (response && response.data) {
                // update cart.items
                const itemsDictionary = {}
                for (let item of response.data) {
                    itemsDictionary[item.id] = item
                }
    
                for (let i = 0; i < cart.items.length; i++) {
                    const oldCartItem = cart.items[i]
                    const newCartItem = itemsDictionary[oldCartItem.id]

                    console.log(oldCartItem, newCartItem)
                    if (oldCartItem.quantity > newCartItem.stock) {
                        newCartItem.quantity = newCartItem.stock
                    }

                    cart.updateItem(oldCartItem.id, newCartItem)
                }
            }
        }

        fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {...cart}
}

export default useUpdatedCart
