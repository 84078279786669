import "../CSS/invoice/invoiceThermal.css"
import { useParams } from "react-router-dom"
import { useState, useEffect } from 'react'
import serverUrl from "../Utilities/ServerUrl"
import axios from 'axios'
import { Spinner } from 'react-bootstrap';
import { format } from "../Utilities/Currency";
import { Link } from 'react-router-dom'

//Component for Invoice Thermal
const InvoiceThermal = () => {
    
  const { orderUUID } = useParams();
  const [ orderData, setOrderData ] = useState();
  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {
    axios.get(`${serverUrl}/data/order/${orderUUID}/`).then(res => {
        setOrderData(res.data);
        setIsLoading(false);
    })
  }, [orderUUID]);

  if (isLoading) {
    return (
      <Spinner className="spinner" animation="border" role="status" size="xl">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  const date = new Date(orderData.datetime_created).toISOString().split('T')[0]

  const items = []
  let total = 0
  for (var orderItem of orderData.order_items) {
    const invoiceThermalItem = (
        <div className="my-2" key={orderItem.product.name}>
                <div className="d-flex justify-content-between">
                    <div className="item-name text-left">{orderItem.product.name}</div>
                    <div className="discount text-right">{ orderItem.discount > 0 ? `-${orderItem.discount}%` : '-' }</div>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="item-total">{orderItem.pcs}x {format(orderItem.price_per_unit)}</div>
                    <div className="item-total-price text-right">{format(orderItem.subtotal)}</div>
                </div>
                    
        </div>
    )

    items.push(invoiceThermalItem)

    total += orderItem.subtotal
  }

  return (
    <>
    
        <div className="container px-0 invoice-actions-container d-print-none" style={{ 'maxWidth': '850px' }}>
            <Link className='btn action-button mx-2 d-print-none' to={`/invoice/${orderData.uuid}`}>Non-Thermal</Link>
            <button className='btn action-button mx-2 d-print-none' onClick={window.print}>Print</button>
        </div>
        <div className="receipt">
            <header>
                <h1>Superjaya</h1>
                <p className="mb-0">Samarinda</p>
                <p className="my-0">WA/Telp: +628135050771</p>
            </header>
            <table className="mytable">
                <tbody>
                    <tr>
                        <td>{date}</td>
                        <td>#{orderData.id}</td>
                        <td>{orderData.handler_name}</td>
                    </tr>
                </tbody>
            </table>

            {items}

            <div className="d-flex justify-content-end mb-3" style={{borderTop: "1px dashed black", borderBottom: "1px dashed black"}}>
                <p className="my-0">Total: {format(total)}</p>
            </div>

            <footer>info@Superjaya.id</footer>
        </div>
    </>
  )
}

export default InvoiceThermal